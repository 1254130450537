import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-start space-x-4 mt-12 mb-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createVNode(_component_router_link, {
      class: "transition hover:drop-shadow-xl",
      to: "/"
    }, {
      default: _withCtx(() => [
        _createTextVNode("home")
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, {
      class: "transition hover:drop-shadow-xl",
      to: "/about"
    }, {
      default: _withCtx(() => [
        _createTextVNode("about")
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, {
      class: "transition hover:drop-shadow-xl",
      to: "/projects"
    }, {
      default: _withCtx(() => [
        _createTextVNode("projects")
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, {
      class: "transition hover:drop-shadow-xl",
      to: "/guestbook"
    }, {
      default: _withCtx(() => [
        _createTextVNode("guestbook")
      ]),
      _: 1
    })
  ]))
}